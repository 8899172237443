tbody, td, tfoot, th, thead, tr {
    padding: 10px;
}

table tbody tr:nth-child(2n) {
  background-color: #f2f2f2;
}

td {
    vertical-align: top;
}

h2 {
    padding: 40px 0px 40px 0px;
}