button {
    padding: 10px 20px;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 25%;
    transition: background-color 0.3s;
}

button:disabled {
    background-color: #3c4147;
    color: #a9a9a9;
    cursor: not-allowed;
    opacity: 0.6;
}

button:hover {
    background: rgb(0, 21, 52);
}

.imgControlButton {
    border-radius: 25%;
    background: rgb(0, 21, 52);
}