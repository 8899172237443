button {
    pointer-events: auto;
    background-color: rgb(252, 153, 55);
    padding: 10px;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
}

button:hover {
    background: rgba(0,0,0,.05);
    transition: opacity .2s ease-in-out
}