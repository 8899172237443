.highlights, .highlight_textarea {
  padding: 10px;
}

.highlight_container {
  display: block;
  margin: 0;
  transform: translateZ(0);
  -webkit-text-size-adjust: none;
  margin-top: 20px;
}

.highlight_textarea {
  margin: 0;
  z-index: 2;
  display: block;
  position: absolute;
  color: #444;
  background-color: transparent;
  width: 100%;
  height: 150px;
  resize: none !important;
  border: 2px solid lightgray;
  border-radius: 5px;
  overflow: auto;
  transition: transform 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* Fully transparent */
  }
  50% {
    opacity: 1; /* Fully opaque */
  }
  100% {
    opacity: 0; /* Fully opaque */
  }
}

mark {
  border-radius: 2px;
  color: transparent;
  background-color: #b1d5e5 !important;
  opacity: 0; /* Start fully transparent */
  animation: fadeIn 1.4s ease-in-out; /* Apply animation */
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
  margin-top: 1.4px;
  margin-left: -2px;
}

.backdrop {
  position: absolute;
  z-index: 1;
  margin: 0;
  background-color: #fff;
  overflow: auto;
  resize: none !important;
  pointer-events: none;
  transition: transform 1s;
  width: 100%;
  height: 150px;
}