/* .required-input {
  border: 2px solid currentColor;
}

.required-input:invalid {
  border: 2px dashed red;
}

.required-input:invalid:focus {
  background-color:darksalmon;
} */


#routes-container {
  border: solid 1px;
  border-radius: 0.6rem;
  padding: 1rem;
  margin-top: 1rem;
  border-color: lightgray;
  display: none;
}

.visible {
  display: block !important;
}

.loading-circle {
  background: none;
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #3498db;
  /* Blue */
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%);
  width: 70px;
  height: 70px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .formclass {
    flex: 0 0 auto;
    width: 88%;
  }
}