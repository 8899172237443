#hypothesisField {
  min-width: 20vw;
  min-height: 10vh;
  min-width: 20vw;
  min-height: 10vh;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  margin: 5px;
}

.speechIndicator {
  min-width: 12px;
  min-height: 12px;
  display: inline-flex;
  width: fit-content;
  align-items: baseline;
}

.outerLine,
.innerLine {
  background-color: chocolate;
  border-radius: 2px;
  min-width: 6px;
  height: 18px;
  margin: 2px;
}

.outerLine {
  animation: 1s infinite outerLineKF;
}

.innerLine {
  height: 24px;
  animation: 1s infinite innerLineKF;
}

@keyframes outerLineKF {
  0% {
    height: 12px;
  }

  50% {
    height: 24px;
  }

  100% {
    height: 12px;
  }
}

@keyframes innerLineKF {
  0% {
    height: 24px;
  }

  50% {
    height: 12px;
  }

  100% {
    height: 24px;
  }
}