.dictationButton {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 2%;
  border-radius: 100%;
  height: 100px;
  width: 100px;
  font-size: xxx-large;
  padding: 1vh;
  z-index: 10;
}

.dictationButtonConnected {
  background: #466dd6;
}

.dictationButtonOff {
  background: indianred;
}

/* .dictationButtonConnecting {
; */
/* } */

.dictationButton:hover {
  cursor: pointer;
  background: rgb(0, 21, 52);
}