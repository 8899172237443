.formDivDark {
    border: 2px solid lightgray;
    border-radius: 5px;
    background: #EFF0F2;
    margin: 10px 0;
    padding: 20px 15px;
    position: relative;
    min-height:300px;
    min-width: 550px; /* support only medium size */
}

.formDivLight {
    border: 2px solid lightgray;
    border-radius: 5px;
    background: white;
    margin: 10px 0;
    padding: 20px 15px
}

.MuiGrid-item:has(> h6) {
    flex-wrap: wrap;
    align-content: center;
    display: grid;
    flex-grow: 0;
    width: 75px;
}

h3 {
    font-size: 1.2rem;
}
h2 {
    font-size: calc(0.8rem + .9vw) !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* Jiggle Animation */
@keyframes jiggle {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
}
.jiggle {
  animation: jiggle 0.5s ease-in-out;
}
.green-background {
  background-color: lightgreen;
  transition: background-color 0.5s;
}

.invalidInput {
  border: 3px solid rgb(212, 15, 34) !important;
}
.invalidInput::after {
  content:'';
  position:absolute;
  width:12px;
  height:12px;
  left:50%;
  transform:translate(-50%,50%) rotate(45deg);
  background-color:#EEEEEE;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
}

@media (max-width: 1200px) { /* Adjust this breakpoint as needed */
  #lesionDiv {
    flex: 1 1 100%; /* Take full width */
    max-width: 100%; /* Max width is 100% */
  }
  #canvasContainer {
    flex: 1 1 100%; /* Also take full width */
    max-width: 100%; /* Max width is 100% */
  }
}